import { createSlice } from "@reduxjs/toolkit";

import { REQ_STATUSES } from "../../constants/appUtilsConstants";
import { pointsBalanceErrorMessage } from "../../constants/toastMessages";
import { getPointsBalance } from "../../thunks/dashboardThunks";
import { raiseErrorToast } from "../../utils/toastUtils";

const initialState = {
  pointsBalance: 0,
  hasErrors: false,
  lastFetchedOn: "",
  status: REQ_STATUSES.idle,
};

const getPointsBalancePending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
  hasErrors: false,
});

const getPointsBalanceFulfilled = (
  state,
  { internal_status_code: statusCode, data, errors },
) => {
  if (statusCode === 200) {
    state.status = REQ_STATUSES.succeeded;
    state.hasErrors = false;
    state.lastFetchedOn = new Date().toLocaleString();
    state.pointsBalance = data["points_balance"];
  } else if (errors) {
    state.status = REQ_STATUSES.failed;
    state.hasErrors = true;
    raiseErrorToast(pointsBalanceErrorMessage);
  }

  return state;
};

const getPointsBalanceRejected = state => {
  raiseErrorToast(pointsBalanceErrorMessage);
  return { ...state, hasErrors: true, status: REQ_STATUSES.failed };
};

const pointsBalanceSlice = createSlice({
  name: "pointsBalance",
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getPointsBalance.pending, state =>
      getPointsBalancePending(state),
    );

    builder.addCase(getPointsBalance.fulfilled, (state, { payload }) =>
      getPointsBalanceFulfilled(state, payload),
    );

    builder.addCase(getPointsBalance.rejected, state =>
      getPointsBalanceRejected(state),
    );
  },
});

export const { actions } = pointsBalanceSlice;

export const pointsBalanceSelector = state => state.pointsBalance;

export default pointsBalanceSlice.reducer;
