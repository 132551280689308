import { createSlice } from "@reduxjs/toolkit";

import { getActivityHistory } from "../../thunks/dashboardThunks";
import {
  ACTIVITIES_CACHE_LIMIT,
  REQ_STATUSES,
  FILTER_INITIAL_STATE,
  PAGE_SETTINGS,
  ORDER_SETTINGS,
} from "../../constants/appUtilsConstants";
import { raiseErrorToast } from "../../utils/toastUtils";
import { activityHistoryErrorMessage } from "../../constants/toastMessages";

const initialState = {
  activityHistoryData: {},
  currentPage: 0,
  activitiesCount: 0,
  status: REQ_STATUSES.idle,
  filterDialog: {
    filterSettings: FILTER_INITIAL_STATE,
    pageSettings: PAGE_SETTINGS,
    orderSettings: ORDER_SETTINGS,
    hasFilterOpened: false,
  },
};

const cachingActivityHistoryData = (activityHistoryData, data) => {
  const { activities, current_page_number: page } = data;
  activityHistoryData[page] = activities;
  const activityObjectKeys = Object.keys(activityHistoryData);
  if (activityObjectKeys.length * activities.length > ACTIVITIES_CACHE_LIMIT) {
    if (activityObjectKeys[0] < page)
      delete activityHistoryData[activityObjectKeys[0]];
    else
      delete activityHistoryData[
        activityObjectKeys[activityObjectKeys.length - 1]
      ];
  }
  return activityHistoryData;
};

const activityHistoryPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
});

const activityHistoryFulfilled = (state, action) => {
  const { data, internal_status_code: statusCode } = action.payload;
  if (statusCode === 200) {
    state.activityHistoryData = cachingActivityHistoryData(
      state.activityHistoryData,
      data,
    );
    state.currentPage = data.current_page_number;
    state.activitiesCount = data.total_activities_count;
    state.status = REQ_STATUSES.succeeded;
  } else {
    state.status = REQ_STATUSES.failed;
    raiseErrorToast(activityHistoryErrorMessage);
  }
  return state;
};

const activityHistoryRejected = state => {
  state.status = REQ_STATUSES.failed;
  raiseErrorToast(activityHistoryErrorMessage);
};

const setInitialState = state => ({
  ...state,
  activityHistoryData: {},
  currentPage: 0,
  activitiesCount: 0,
  status: REQ_STATUSES.idle,
});

const activityHistorySlice = createSlice({
  name: "activityHistory",
  initialState,

  reducers: {
    clearActivityHistory: state => setInitialState(state),
    toggleFilterMenu: state => {
      state.filterDialog.hasFilterOpened = !state.filterDialog.hasFilterOpened;
      return state;
    },
    updateOrderSettings: (state, action) => {
      const { payload } = action;
      state.activityHistoryData = {};
      state.filterDialog.pageSettings = PAGE_SETTINGS;
      state.filterDialog.orderSettings = payload;
      return state;
    },
    updatePageNumber: (state, action) => {
      const { payload } = action;
      state.filterDialog.pageSettings.page = payload;
      return state;
    },
    updatePageSize: (state, action) => {
      const { payload } = action;
      state.filterDialog.pageSettings.perPage = payload;
      state.filterDialog.pageSettings.page = 1;
      return state;
    },
    updateDateRange: (state, action) => {
      const { payload } = action;
      const { name, value } = payload;
      state.filterDialog.pageSettings = PAGE_SETTINGS;
      state.filterDialog.filterSettings.dateRange = {
        ...state.filterDialog.filterSettings.dateRange,
        [name]: value,
      };
      return state;
    },
    updateActivityType: (state, action) => {
      const { payload } = action;
      state.filterDialog.pageSettings = PAGE_SETTINGS;
      state.filterDialog.filterSettings.activityType = payload;
      return state;
    },
    updateIsHidden: (state, action) => {
      const { payload } = action;
      state.filterDialog.pageSettings = PAGE_SETTINGS;
      state.filterDialog.filterSettings.isHidden = payload;
      return state;
    },
    resetFilterSettings: state => {
      state.filterDialog = {
        filterSettings: FILTER_INITIAL_STATE,
        pageSettings: PAGE_SETTINGS,
        orderSettings: ORDER_SETTINGS,
        hasFilterOpened: false,
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(getActivityHistory.pending, state =>
      activityHistoryPending(state),
    );

    builder.addCase(getActivityHistory.fulfilled, (state, action) =>
      activityHistoryFulfilled(state, action),
    );

    builder.addCase(getActivityHistory.rejected, state =>
      activityHistoryRejected(state),
    );
  },
});

export const activitySelector = state => state.activity;
export const filterDialogSelector = state => state.activity.filterDialog;
export const activityStatusSelector = state => state.activity.status;
export const activityHistoryDataSelector = page => state =>
  state.activity.activityHistoryData[page];
export const {
  clearActivityHistory,
  toggleFilterMenu,
  updateOrderSettings,
  updatePageNumber,
  updatePageSize,
  updateDateRange,
  updateActivityType,
  updateIsHidden,
  resetFilterSettings,
} = activityHistorySlice.actions;
export default activityHistorySlice.reducer;
