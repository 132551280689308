import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import DashboardCard from "./DashboardCard";
import { getPointsBalance } from "../../../thunks/dashboardThunks";
// eslint-disable-next-line max-len
import { pointsBalanceSelector } from "../../../store/slices/pointsBalanceSlice";
import { REQ_STATUSES } from "../../../constants/appUtilsConstants";
import { LoyaltyPointsIconStyles } from "../../../styles/dashboardCardStyles";

const ActivityHistoryPoints = () => {
  const dispatch = useDispatch();

  const { status, pointsBalance: pointsEarned } = useSelector(
    pointsBalanceSelector,
  );

  useEffect(() => {
    if (!(status === REQ_STATUSES.succeeded)) {
      dispatch(getPointsBalance());
    }
  }, []);

  return (
    <DashboardCard
      status={status}
      cardIcon={<LoyaltyPointsIconStyles />}
      cardHeadingText="Points Balance"
      cardBodyText={pointsEarned}
      cardFooterElement=""
    />
  );
};

export default ActivityHistoryPoints;
