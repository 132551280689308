import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";

import {
  API_RESPONSE_CODES,
  REQ_STATUSES,
} from "../../constants/appUtilsConstants";
import { postPointsAdjustment } from "../../thunks/pointsAdjustment";
import { raiseErrorToast, raiseSuccessToast } from "../../utils/toastUtils";
import {
  pointsAdjustmentLogErrorMessage,
  pointsAdjustmentLogSuccessMessage,
} from "../../constants/toastMessages";

const initialState = {
  status: REQ_STATUSES.idle,
};

const postPointAdjustmentPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
});

const postPointAdjustmentFulfilled = (
  state,
  { internal_status_code: statusCode, errors },
) => {
  if (API_RESPONSE_CODES.SUCCESS.includes(statusCode)) {
    state.status = REQ_STATUSES.succeeded;
    raiseSuccessToast(pointsAdjustmentLogSuccessMessage);
  } else if (!isEmpty(errors)) {
    state.status = REQ_STATUSES.failed;
    raiseErrorToast(pointsAdjustmentLogErrorMessage);
  }

  return state;
};

const postPointAdjustmentRejected = state => {
  raiseErrorToast(pointsAdjustmentLogErrorMessage);
  return { ...state, status: REQ_STATUSES.failed };
};

const pointsAdjustmentSlice = createSlice({
  name: "pointsAdjustment",
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(postPointsAdjustment.pending, state =>
      postPointAdjustmentPending(state),
    );

    builder.addCase(postPointsAdjustment.fulfilled, (state, { payload }) =>
      postPointAdjustmentFulfilled(state, payload),
    );

    builder.addCase(postPointsAdjustment.rejected, state =>
      postPointAdjustmentRejected(state),
    );
  },
});

export const { actions } = pointsAdjustmentSlice;

export default pointsAdjustmentSlice.reducer;
