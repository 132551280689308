import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  SWEEPS_PARTICIPATION_URI,
  TOTAL_WINNINGS_URI,
  TOTAL_POINTS_URI,
  ACTIVITY_HISTORY_URI,
} from "../constants/apiUri";
import { REQUEST_METHODS } from "../constants/appUtilsConstants";
import { CARD_HOLDER_ID } from "../constants/storageKeys";
import { getApiDomain, baseApi, checkRequestSuccess } from "../utils/apiUtils";
import CSRApiError from "../utils/errorHandlerUtils";
import { getStorageItem, getToken } from "../utils/storageUtils";
import { getApiHeaders } from "../utils/urlUtils";

export const getTotalWinnings = createAsyncThunk(
  "totalWinnings/getTotalWinnings",
  async () => {
    const externalUserId = getStorageItem(CARD_HOLDER_ID);
    const token = getToken();
    const headers = getApiHeaders(token);
    const apiUri = `${getApiDomain()}${TOTAL_WINNINGS_URI}${externalUserId}/`;
    const apiResponse = await baseApi(apiUri, headers, REQUEST_METHODS.GET);
    if (!checkRequestSuccess(apiResponse)) {
      throw new CSRApiError(apiResponse);
    }

    return apiResponse;
  },
);

export const getPointsBalance = createAsyncThunk(
  "pointsBalance/getPointsBalance",
  async () => {
    const externalUserId = getStorageItem(CARD_HOLDER_ID);
    const token = getToken();
    const headers = getApiHeaders(token);
    const apiUri = `${getApiDomain()}${TOTAL_POINTS_URI}${externalUserId}/`;

    const apiResponse = await baseApi(apiUri, headers, REQUEST_METHODS.GET);
    if (!checkRequestSuccess(apiResponse)) {
      throw new CSRApiError(apiResponse);
    }

    return apiResponse;
  },
);

export const getSweepsParticipationStatus = createAsyncThunk(
  "sweepsParticipationStatus/getSweepsParticipationStatus",
  async () => {
    const externalUserId = getStorageItem(CARD_HOLDER_ID);
    const token = getToken();
    const headers = getApiHeaders(token);
    // eslint-disable-next-line max-len
    const apiUri = `${getApiDomain()}${SWEEPS_PARTICIPATION_URI}${externalUserId}/`;

    const apiResponse = await baseApi(apiUri, headers, REQUEST_METHODS.GET);
    if (!checkRequestSuccess(apiResponse)) {
      throw new CSRApiError(apiResponse);
    }

    return apiResponse;
  },
);

export const getActivityHistory = createAsyncThunk(
  "activityHistory/getActivityHistory",
  async queryParams => {
    const externalUserId = getStorageItem(CARD_HOLDER_ID);
    const token = getToken();
    const headers = getApiHeaders(token);
    const uri = `${getApiDomain()}${ACTIVITY_HISTORY_URI}${externalUserId}/`;
    const apiUri = !queryParams ? uri : `${uri}?${queryParams}`;
    const apiResponse = await baseApi(apiUri, headers, REQUEST_METHODS.GET);
    if (!checkRequestSuccess(apiResponse)) {
      throw new CSRApiError(apiResponse);
    }

    return apiResponse;
  },
);
