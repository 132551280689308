import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableFooter,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import TableHeaders from "../../common/TableHeaders";
import TablePaginationControls from "../../common/TablePaginationControls";
import ActivityTableRow from "./ActivityTableRow";
import {
  clearActivityHistory,
  activitySelector,
  updateOrderSettings,
  filterDialogSelector,
  updatePageNumber,
  updatePageSize,
} from "../../../store/slices/activityHistorySlice";
import {
  ACTIVITY_TABLE_HEADERS,
  REQ_STATUSES,
  ORDER_TYPE,
} from "../../../constants/appUtilsConstants";
import TableContentContainer from "../../common/TableContent";

const ActivityTableContainer = () => {
  const dispatch = useDispatch();
  const { orderSettings, pageSettings } = useSelector(filterDialogSelector);
  const { activityHistoryData, activitiesCount, status } =
    useSelector(activitySelector);

  const { page, perPage } = pageSettings;
  const { order, orderBy } = orderSettings;
  const { ASCENDING, DESCENDING } = ORDER_TYPE;

  const handleRequestOrder = (event, property) => {
    if (status === REQ_STATUSES.succeeded) {
      dispatch(
        updateOrderSettings({
          order:
            orderBy === property && order === ASCENDING
              ? DESCENDING
              : ASCENDING,
          orderBy: property,
        }),
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableHeaders
              orderSettings={orderSettings}
              headers={ACTIVITY_TABLE_HEADERS}
              onRequestOrder={handleRequestOrder}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableContentContainer
            CustomTableRow={ActivityTableRow}
            isLoading={status === REQ_STATUSES.loading}
            data={activityHistoryData[page] || []}
            id="activity_id"
            rows={perPage}
            colSpan={6}
          />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20]}
              colSpan={6}
              count={status === REQ_STATUSES.succeeded ? activitiesCount : 0}
              rowsPerPage={perPage}
              page={status === REQ_STATUSES.succeeded ? page - 1 : 0}
              onPageChange={(event, newPage) =>
                dispatch(updatePageNumber(newPage))
              }
              onRowsPerPageChange={event => {
                dispatch(clearActivityHistory());
                // eslint-disable-next-line no-unused-expressions
                status === REQ_STATUSES.succeeded &&
                  dispatch(updatePageSize(parseInt(event.target.value, 10)));
              }}
              ActionsComponent={TablePaginationControls}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ActivityTableContainer;
