import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { PAGE_TITLES, REQ_STATUSES } from "../constants/appUtilsConstants";
import { authUser } from "../thunks/sessionThunks";
import { DASHBOARD_URL, ERROR_URL } from "../constants/pageUrls";
import { ERROR_MESSAGES, ERROR_STATES } from "../constants/displayTexts";
import { sessionSelector } from "../store/slices/sessionSlice";
import Loading from "./Loading";
import { makeQueryParams } from "../utils/urlUtils";

const Login = () => {
  const [searchParams] = useSearchParams();
  const query = Object.fromEntries([...searchParams]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    isAuthed,
    errorDetail: sessionErrorDetail,
    status,
  } = useSelector(sessionSelector);

  useEffect(() => {
    const { jwt } = query;
    if (!isAuthed) {
      if (jwt) {
        dispatch(authUser(query));
      } else dispatch(authUser.rejected(ERROR_MESSAGES.AUTH_SESSION_INVALID));
    } else {
      navigate(`/${DASHBOARD_URL}`, { replace: true });
    }
  }, [isAuthed]);

  useEffect(() => {
    if (sessionErrorDetail) {
      const errorQueryParams = makeQueryParams(
        "error",
        ERROR_STATES.AUTH_SESSION_INVALID,
      );

      navigate(`/${ERROR_URL}${errorQueryParams}`, { replace: true });
    }
  }, [sessionErrorDetail]);

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLES.login}</title>
      </Helmet>
      {status === REQ_STATUSES.loading && (
        <Loading loadingText={ERROR_MESSAGES.LOGIN_LOADING} />
      )}
    </>
  );
};

export default Login;
