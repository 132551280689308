import isEmpty from "lodash/isEmpty";

import {
  API_RESPONSE_CODES,
  AUTH_API_QUERY_PARAMS,
  REQUEST_METHODS,
} from "../constants/appUtilsConstants";
import { getStorageItem } from "./storageUtils";
import { PP_CSR_REFERRER } from "../constants/storageKeys";

export const getApiDomain = () => {
  const referrer = getStorageItem(PP_CSR_REFERRER);
  return referrer === "kd"
    ? process.env.REACT_APP_KD_API_URL
    : process.env.REACT_APP_PP_API_URL;
};

export const baseApi = (apiUri, headers, method, body = null) =>
  fetch(apiUri, {
    method,
    headers,
    ...(method === REQUEST_METHODS.POST && { body: JSON.stringify(body) }),
  }).then(response => response.json());

export const checkRequestSuccess = ({
  data,
  errors,
  internal_status_code: statusCode,
}) =>
  data && isEmpty(errors) && API_RESPONSE_CODES.SUCCESS.includes(statusCode);

export const checkAuthQueryParams = query =>
  AUTH_API_QUERY_PARAMS.every(item =>
    Object.prototype.hasOwnProperty.call(query, item),
  );
