import { createAsyncThunk } from "@reduxjs/toolkit";

import { SWEEPSTAKES_URI } from "../constants/apiUri";
import { REQUEST_METHODS } from "../constants/appUtilsConstants";
import { getApiDomain, baseApi, checkRequestSuccess } from "../utils/apiUtils";
import CSRApiError from "../utils/errorHandlerUtils";
import { getToken } from "../utils/storageUtils";
import { getApiHeaders } from "../utils/urlUtils";

export const getSweepstakes = createAsyncThunk(
  "sweepstakes/getSweepstakes",
  async queryParams => {
    const token = getToken();
    const headers = getApiHeaders(token);
    const uri = `${getApiDomain()}${SWEEPSTAKES_URI}`;
    const apiUri = !queryParams ? uri : `${uri}?${queryParams}`;
    const apiResponse = await baseApi(apiUri, headers, REQUEST_METHODS.GET);
    if (!checkRequestSuccess(apiResponse)) {
      throw new CSRApiError(apiResponse);
    }

    return apiResponse;
  },
);
