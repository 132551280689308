import { styled } from "@mui/material/styles";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Loyalty from "@mui/icons-material/Loyalty";

import { IconStyles } from "./commonStyles";

export const TotalWinningsCardStyles = styled(AttachMoneyIcon)(({ theme }) => ({
  ...IconStyles,
  background: theme.palette.primary.light,
  padding: theme.spacing(1),
}));

export const SweepsIconStyles = styled(LocalActivityIcon)(
  ({ theme, backgroundcolor }) => ({
    ...IconStyles,
    background: backgroundcolor,
    padding: theme.spacing(1),
  }),
);

export const LoyaltyPointsIconStyles = styled(Loyalty)(({ theme }) => ({
  ...IconStyles,
  background: theme.palette.primary.light,
  padding: theme.spacing(1),
}));

export const OnboardingIconStyles = styled(HandshakeIcon)(({ theme }) => ({
  ...IconStyles,
  background: theme.palette.primary.light,
  padding: theme.spacing(1),
}));
