import qs from "qs";
import transform from "lodash/transform";
import snakeCase from "lodash/snakeCase";
import kebabCase from "lodash/kebabCase";
import trim from "lodash/trim";
import values from "lodash/values";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

import {
  PP_CSR_SITE_IDENTIFIER,
  ORDER_TYPE,
} from "../constants/appUtilsConstants";
import { REQ_HEADERS } from "../constants/apiUri";

export const getApiHeaders = authToken => ({
  "Content-Type": "application/json",
  "Accept-Language": "en",
  Authorization: authToken ? `JWTToken ${authToken}` : null,
  [REQ_HEADERS.ppSiteId]: PP_CSR_SITE_IDENTIFIER,
});

const cleanUpQueryKeys = queryObject =>
  transform(pickBy(queryObject, identity), (resultObject, value, key) => {
    const newKey = snakeCase(trim(key));
    resultObject[newKey] = value;
  });

export const getPageParams = pageSettings => {
  const cleanPageSettings = {
    ...pageSettings,
    pageSize: pageSettings.perPage,
  };
  return qs.stringify(cleanUpQueryKeys(cleanPageSettings));
};

export const getActivityQueryParams = ({
  pageSettings,
  orderSettings,
  filterSettings,
}) => {
  const pageParams = getPageParams(pageSettings);

  const cleanedOrderSettings = {
    ordering:
      orderSettings.order === ORDER_TYPE.DESCENDING
        ? `-${orderSettings.orderBy}`
        : orderSettings.orderBy,
  };

  const orderParams = qs.stringify(cleanUpQueryKeys(cleanedOrderSettings));

  const cleanedFilterSettings = {
    activityType: kebabCase(filterSettings.activityType),
    dateRange:
      filterSettings.dateRange.fromStartDate &&
      filterSettings.dateRange.toEndDate &&
      values(filterSettings.dateRange),
    isHidden: filterSettings.isHidden === "All" ? "" : filterSettings.isHidden,
  };
  const filterParams = qs.stringify(cleanUpQueryKeys(cleanedFilterSettings), {
    arrayFormat: "comma",
  });

  return [pageParams, orderParams, filterParams].join("&");
};

export const makeQueryParams = (
  key,
  value,
  baseURL = process.env.REACT_APP_AUTHED_PP_URL,
) => {
  const url = new URL(baseURL);
  url.searchParams.set(key, value);

  return `${url.search}`;
};
