import { createSlice } from "@reduxjs/toolkit";

import { REQ_STATUSES } from "../../constants/appUtilsConstants";
import { sweepsParticipationErrorMessage } from "../../constants/toastMessages";
import { getSweepsParticipationStatus } from "../../thunks/dashboardThunks";
import { raiseErrorToast } from "../../utils/toastUtils";

const initialState = {
  sweepsParticipationStatus: "",
  lastUpdatedOn: "",
  hasErrors: false,
  lastFetchedOn: "",
  status: REQ_STATUSES.idle,
};

const getSweepsParticipationStatusPending = state => ({
  ...state,
  status: REQ_STATUSES.loading,
  hasErrors: false,
});

const getSweepsParticipationStatusFulfilled = (
  state,
  { internal_status_code: statusCode, data, errors },
) => {
  if (statusCode === 200) {
    return {
      ...state,
      status: REQ_STATUSES.succeeded,
      hasErrors: false,
      lastFetchedOn: new Date().toLocaleString(),
      sweepsParticipationStatus: data["sweeps_participation_status"],
      lastUpdatedOn: data["last_updated_on"],
    };
  }
  if (errors) {
    state.status = REQ_STATUSES.failed;
    state.hasErrors = true;

    raiseErrorToast(sweepsParticipationErrorMessage);
  }

  return state;
};

const getSweepsParticipationStatusRejected = state => {
  raiseErrorToast(sweepsParticipationErrorMessage);
  return { ...state, hasErrors: true, status: REQ_STATUSES.failed };
};

const sweepsParticipationStatusSlice = createSlice({
  name: "sweepsParticipationStatus",
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getSweepsParticipationStatus.pending, state =>
      getSweepsParticipationStatusPending(state),
    );

    builder.addCase(
      getSweepsParticipationStatus.fulfilled,
      (state, { payload }) =>
        getSweepsParticipationStatusFulfilled(state, payload),
    );

    builder.addCase(getSweepsParticipationStatus.rejected, state =>
      getSweepsParticipationStatusRejected(state),
    );
  },
});

export const { actions } = sweepsParticipationStatusSlice;

export const sweepsParticipationStatusSelector = state =>
  state.sweepsParticipationStatus;

export default sweepsParticipationStatusSlice.reducer;
