import { useDispatch } from "react-redux";
import { Grid, Button, MenuItem, TextField, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Layout from "../components/common/Layout";
import { postPointsAdjustment } from "../thunks/pointsAdjustment";
import {
  POINTS_ADJUSTMENT_REASONS,
  ERROR_MESSAGES,
} from "../constants/displayTexts";
import { PAGE_TITLES } from "../constants/appUtilsConstants";

const PointsAdjustment = () => {
  const dispatch = useDispatch();
  return (
    <Layout pageTitle={PAGE_TITLES.pointsAdjustment}>
      <div>
        <Typography variant="h5" gutterBottom component="h5">
          Offer point adjustment to the User:
        </Typography>
        <Formik
          initialValues={{ pointsOffered: 0, reason: "", notes: "" }}
          validationSchema={Yup.object({
            pointsOffered: Yup.number()
              .min(1, ERROR_MESSAGES.POINTS_ADJUSTMENT_MIN_POINTS)
              .required(ERROR_MESSAGES.REQUIRED_FIELD),
            reason: Yup.string().required(
              ERROR_MESSAGES.POINTS_ADJUSTMENT_REASON_REQUIRED,
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(postPointsAdjustment(values));
            resetForm();
          }}
        >
          {({ values, handleChange, errors }) => (
            <Form>
              <Grid container>
                <Grid item container xs={6}>
                  <Grid item xs={12}>
                    <TextField
                      name="pointsOffered"
                      id="points-offered"
                      label="Points to be offered:"
                      type="number"
                      variant="filled"
                      value={values.pointsOffered}
                      onChange={handleChange}
                      error={!!errors.pointsOffered}
                      helperText={errors?.pointsOffered}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="reason"
                      id="reasons"
                      select
                      label="Reasons for Adjustment"
                      value={values.reason}
                      onChange={handleChange}
                      error={!!errors.reason}
                      helperText={errors?.reason}
                      fullWidth
                      margin="normal"
                    >
                      {POINTS_ADJUSTMENT_REASONS.map(option => (
                        <MenuItem key={option.id} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="h6">
                      Notes:
                    </Typography>
                    <TextField
                      name="notes"
                      id="notes"
                      label="Add Notes (optional)"
                      multiline
                      rows={4}
                      margin="dense"
                      value={values.notes}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid container direction="row-reverse">
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

export default PointsAdjustment;
